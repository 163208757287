import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as vscode from 'vscode'
import * as monaco from 'monaco-editor'
import { initialize } from 'vscode/services'
import getEditorServiceOverride from '@codingame/monaco-vscode-editor-service-override'
import getConfigurationServiceOverride, { updateUserConfiguration, configurationRegistry } from '@codingame/monaco-vscode-configuration-service-override'

await initialize({
  ...getEditorServiceOverride((model, input, sideBySide) => {
    // Open a new editor here and return it
    // It will be called when for instance the user ctrl+click on an import
  }),
  ...getConfigurationServiceOverride()
})
/*
const { registerFileUrl, getApi } = registerExtension({
  "name": "unreal-angelscript",
  "displayName": "Unreal Angelscript",
  "description": "Implements support for unreal-bound angelscript files.",
  "version": "0.0.1",
  "publisher": "Hazelight",
  "engines": {
      "vscode": "^1.65.0"
  },
  "dependencies": {
      "await-notify": "^1.0.1",
      "vscode-debugadapter": "^1.48.0",
      "vscode-languageclient": "^8.1.0"
  },
  "devDependencies": {
      "@types/vscode": "^1.65.0",
      "typescript": "^4.5.5",
      "vscode-test": "^1.3.0"
  }
}, ExtensionHostKind.LocalProcess)
registerFileUrl('/file-extension-path.json', new URL('../vscode-unreal-angelscript/extension/language-configuration.json', import.meta.url).toString())

const vscode1 = await getApi()

vscode1.languages.registerCompletionItemProvider(...)
*/
updateUserConfiguration(`{
  "editor.fontSize": 12,
  "[java]": {
    "editor.fontSize": 15,
  }
}`)

console.log('starting monaco', document.getElementById('container'));
    var editor = monaco.editor.create(document.getElementById('container'), {
      value: ['function x() {', '\tconsole.log("Hello world!");', '}'].join('\n'),
      language: 'javascript'
    });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
